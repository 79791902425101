const pino = require('pino');

const level = 'info';

// @TODO - handle this differently on the front-end.
// if (process) {
//   // @TODO - trap a signal to allow for log level switch
//   const levelLocal = process.env.NODE_ENV === 'production' ? 'info' : 'debug';
// }

const instance = pino({ level });
let enableSOVLogging = false;
function setEnableSOVLogging(isEnabled) {
  enableSOVLogging = isEnabled;
}

// TODO: Replace with a function that accepts a function reference for eval, that'll avoid calculating expensive log strings just to throw them away
const sov = {
  info: (...args) => {
    if (enableSOVLogging) {
      console.log(...args);
    }
  },
  warn: (...args) => {
    if (enableSOVLogging) {
      console.warn(...args);
    }
  },
};

module.exports = {
  main: instance, // Used to route calls to the main logging instance
  sov,
  setEnableSOVLogging,
};
