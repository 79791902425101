function isDevHostname(_hostname) {
  if (!_hostname) return false;

  let hostname = _hostname;

  try {
    // Handle both URLs with and without protocol
    // For hostnames without protocol, we need to add a dummy protocol
    const url = new URL(
      hostname.startsWith('http://') || hostname.startsWith('https://') ? hostname : `http://${hostname}`,
    );
    hostname = url.hostname;
  } catch (error) {
    return false;
  }

  const devHostnames = ['localhost', 'alpha'];

  return devHostnames.some((devHostname) => hostname.includes(devHostname));
}

module.exports = {
  isDevHostname,
};
