import Cookies from 'js-cookie';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useAuth0 } from '../../../../libraries/react-auth0-spa';
import usePreventScroll from '../../../hooks/usePreventScroll';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { LayoutMixin } from '../../../theme';
import theme, { BREAKPOINTS } from '../../../theme/theme';
import { BannerIcon, CloseIcon, MenuIcon, WhiteBannerIcon } from '../../Icons';
import BookADemoButton from '../BookADemoButton';
import BreakpointBox from '../BreakpointBox';
import ButtonLink from '../ButtonLink';
import { LinkButtonMixin } from '../ButtonLink/ButtonLink';
import SocialMedia from '../SocialMedia';
import SVGSocialButton from '../SocialMedia/SVGSocialButton';
import { StyledAContentBottom, StyledItem, StyledWrapperContent } from '../../../pages/mobileLandingPage';
import { isDevHostname } from '../../../../../common/auth';

export var NavbarVariants;
(function (NavbarVariants) {
  NavbarVariants['PRIMARY'] = 'primary';
  NavbarVariants['SECONDARY'] = 'secondary';
})(NavbarVariants || (NavbarVariants = {}));
const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  transition: background 0.3s;
  background: #fff;
  width: 100%;
`;
export const StyledWrapper = styled.div`
  ${LayoutMixin};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledButton = styled.button`
  background-color: transparent;
  cursor: pointer;
`;
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const StyledNavbar = styled.nav`
  background-color: ${theme.colors['Moonlit Ocean']};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: sticky;
  overflow-y: auto;
  top: 0;
  z-index: 99;
  @media screen and (min-width: ${theme.breakpoints[1440]}) {
    display: none;
  }
`;
export const StyledHeaderNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px 0;

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    padding: 35px 0;
  }
`;
const List = styled.div`
  flex-grow: 1;
`;
const FooterWrapper = styled.div`
  margin-top: auto;
  padding: 24px 0px;
`;
const WrapperTerms = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${theme.breakpoints[768]}) {
    width: min-content;
    gap: 40px;
    justify-content: center;
    display: flex;
  }
`;
const StyledTitleList = styled.h1`
  font-family: ${theme.fontFamily.Inter};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${theme.colors.White};
  margin-bottom: 16px;
`;
const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const StyledBottomBookADemoButton = styled(BookADemoButton)`
  width: 100%;
  max-width: 754px;
`;
const StyledSocialMedia = styled(SocialMedia)`
  align-items: center;
  justify-content: center;
  padding: 37px 0px;

  @media screen and (min-width: ${theme.breakpoints[834]}) {
    padding-top: 60px;
    padding-bottom: 96px;
  }
`;
const StyledBreakpointButtonContent = styled(BreakpointBox)`
  align-items: center;
  gap: 48px;
`;
const StyledBookADemoButton = styled(BookADemoButton)`
  padding: 16px 40px;
`;
const StyledButtonLink = styled(ButtonLink)`
  color: ${theme.colors.White};
`;
const StyledButtonAnchor = styled.a`
  ${LinkButtonMixin}
  color: ${theme.colors.White};
`;
const StyledBannerIcon = styled(BannerIcon)`
  ${({ variant }) =>
    variant === NavbarVariants.SECONDARY &&
    css`
      path {
        fill: ${theme.colors.White};
      }
    `}
`;
export const StyledMenuIcon = styled(MenuIcon)`
  ${({ variant }) =>
    variant === NavbarVariants.SECONDARY &&
    css`
      path {
        stroke: ${theme.colors.White};
      }
    `}
`;
function Navbar({ className, isNavbarOpen, setIsNavbarOpen, variant = NavbarVariants.PRIMARY }) {
  const mobileApp = Cookies.get('bannerMobileApp');
  const { loginWithRedirect } = useAuth0();
  const { scrollToTop } = useScrollToTop();
  const shouldScrollToTopRef = useRef(false);
  usePreventScroll({ id: 'navbar', open: isNavbarOpen });
  const handlOnScrollToTop = () => {
    setIsNavbarOpen(false);
    shouldScrollToTopRef.current = true;
  };
  useEffect(() => {
    if (!shouldScrollToTopRef.current) return;
    scrollToTop();
    shouldScrollToTopRef.current = false;
  }, [isNavbarOpen, scrollToTop]);

  return (
    <>
      {!isNavbarOpen && (
        <StyledContainer className={className}>
          <StyledWrapper>
            <StyledHeader>
              {!mobileApp && (
                <Link to="/" aria-label="go to home">
                  <StyledBannerIcon variant={variant} />
                </Link>
              )}
              <StyledBreakpointButtonContent initialDisplay="none" breakpoints={{ [BREAKPOINTS.xl]: 'flex' }}>
                <StyledBookADemoButton className="navbar-cta-button" />
                <ButtonLink
                  to=""
                  small
                  onClick={() =>
                    isDevHostname(window.location.href)
                      ? loginWithRedirect({
                          role: 'manager',
                          action: 'login',
                          connection: 'google-oauth2',
                        })
                      : loginWithRedirect({
                          role: 'manager',
                          action: 'login',
                        })
                  }
                >
                  Login {'->'}
                </ButtonLink>
              </StyledBreakpointButtonContent>
              <BreakpointBox breakpoints={{ [BREAKPOINTS.xl]: 'none' }}>
                <StyledButton
                  style={mobileApp ? { display: 'none' } : undefined}
                  onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                  aria-label="menu"
                  data-cy="banner-menu"
                >
                  <StyledMenuIcon variant={variant} />
                </StyledButton>
              </BreakpointBox>
            </StyledHeader>
          </StyledWrapper>
        </StyledContainer>
      )}
      {isNavbarOpen && (
        <StyledNavbar className="navbar-mobile">
          <StyledWrapper>
            <StyledHeaderNav>
              <SVGSocialButton onClick={handlOnScrollToTop}>
                <WhiteBannerIcon />
              </SVGSocialButton>
              <StyledButton onClick={() => setIsNavbarOpen(!isNavbarOpen)}>
                <CloseIcon />
              </StyledButton>
            </StyledHeaderNav>

            <List>
              <StyledButtonLink
                to=""
                small
                onClick={() =>
                  isDevHostname(window.location.href)
                    ? loginWithRedirect({
                        role: 'manager',
                        action: 'login',
                        connection: 'google-oauth2',
                      })
                    : loginWithRedirect({
                        role: 'manager',
                        action: 'login',
                      })
                }
              >
                Login {'->'}
              </StyledButtonLink>
              {!mobileApp && (
                <>
                  <StyledListContainer>
                    {/* <StyledTitleList>Company</StyledTitleList> */}
                    {/* <StyledButtonLink to="" big>
                  About Us
                </StyledButtonLink> */}
                    {/* <StyledButtonLink to="" big>
                  Careers
                </StyledButtonLink> */}
                    <StyledButtonLink
                      to={{ pathname: 'https://withbanner.com/info/' }}
                      target="_blank"
                      aria-label="go to info"
                      big
                    >
                      Info
                    </StyledButtonLink>
                    <StyledButtonLink to={{ pathname: 'https://blog.withbanner.com/' }} target="_blank" big>
                      Blog
                    </StyledButtonLink>
                  </StyledListContainer>
                  <StyledListContainer>
                    <StyledTitleList>Contact Us</StyledTitleList>
                    <StyledButtonAnchor href="mailto:info@withbanner.com" $big $underline>
                      info@withbanner.com
                    </StyledButtonAnchor>
                    <StyledButtonAnchor href="tel:+4152317512" $big $underline>
                      (415) 231-7512
                    </StyledButtonAnchor>
                  </StyledListContainer>
                </>
              )}
            </List>
            {!mobileApp && (
              <>
                <StyledButtonContainer>
                  <StyledBottomBookADemoButton />
                </StyledButtonContainer>
                <StyledSocialMedia />
              </>
            )}
            {mobileApp && (
              <FooterWrapper>
                <StyledWrapperContent>
                  <StyledAContentBottom>
                    <WrapperTerms>
                      <StyledItem to="/terms">Terms of use</StyledItem>
                      <StyledItem to="/privacy">Privacy Policy</StyledItem>
                      <StyledItem to="/acceptable">Acceptable Use</StyledItem>
                    </WrapperTerms>
                  </StyledAContentBottom>
                </StyledWrapperContent>
              </FooterWrapper>
            )}
          </StyledWrapper>
        </StyledNavbar>
      )}
    </>
  );
}
export default Navbar;
